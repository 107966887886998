import {MenuCTAs, MenuLabels} from "@/models";
import productDetails, {ProductTab} from "./product-details.data";
import {files} from "@/assets";
import Whatsapp from "@/utils/whatsapp";

export const PRODUCT_DETAILS_PREFIX = "tudo-sobre-toro";
export const PRODUCT_DETAILS_REF: MenuLabels = {
    numberId: 5,
    id: "tudo-sobre-toro",
    label: "Tudo sobre a nova Toro",
    slug: PRODUCT_DETAILS_PREFIX,
    url: `/${PRODUCT_DETAILS_PREFIX}`,
    nestedPrefix: PRODUCT_DETAILS_PREFIX,
};

const getProductDetails = (pDetails: ProductTab[]): MenuLabels[] => {
    if (!pDetails) {
        return [];
    }
    return pDetails.reduce((prev, item, index) => {
        return [
            ...prev,
            {
                id: `data-menu-details-${index}`,
                label: item.title,
                slug: `${item.slug}`,
                url: `${item.slug}`,
                nestedPrefix: PRODUCT_DETAILS_PREFIX,
                reference: PRODUCT_DETAILS_REF,
                tabId: item.id,
                isDetails: true,
            } as MenuLabels,
        ];
    }, [] as MenuLabels[]);
};

export const links = {
    reserve: "https://toro.fiat.com.br/monte.html#versao",
    financiamento: "https://toro.fiat.com.br/monte.html#versao",
    monte: "https://toro.fiat.com.br/monte.html#versao",
    concessionarias: "https://www.fiat.com.br/concessionarias.html",
    // whatsapp: "https://api.whatsapp.com/message/5NED64RQ2XDEF1?autoload=1&app_absent=0",
    whatsapp: Whatsapp.contact(),
    compre: "https://www.fiat.com.br/compre/fiat-toro.html",
    ofertas: "https://ofertas.fiat.com.br/?q=toro",
    converse: "/",
    interesse: "/",
    fichaTecnica: "/",
};

export const COMPRE_TITLE = "Compre a sua";
export const TENHO_INTERESSE = "Tenho interesse";
export const MONTE_TITLE = "Monte a sua";
export const FINANCIAMENTO_TITLE = "Simule seu financimento";
export const CONCESSIONARIAS_TITLE = "Concessionárias";
export const CONVERSE = "Converse com a fiat";
export const FICHA_TECNICA = "Baixar ficha técnica";
export const RESERVE_TITLE = "Reserve a sua";
export const OFERTAS = "Ofertas"

export const notMenuAnchors: () => MenuLabels[] = () => [
    {
        numberId: 0,
        id: "notMenuAnchors-0",
        label: "",
        url: "/",
        slug: "sound-design",
    },
];

export const dataMenuLabelMobile: () => MenuLabels[] = () => [
    {
        numberId: 0,
        id: "datamenu-0",
        label: "Nova Fiat Toro",
        url: "/",
        slug: "",
    },
    {
        numberId: 1,
        id: "datamenu-1",
        label: "Design incomparável",
        url: "/design-incomparavel",
        slug: "design-incomparavel",
    },
    {
        numberId: 2,
        id: "datamenu-2",
        label: "Todas as versões",
        url: "/versoes",
        slug: "versoes",
    },
    {
        numberId: 3,
        id: "datamenu-3",
        label: "Fotos",
        url: "/fotos",
        slug: "fotos",
    },
    {
        numberId: 4,
        id: "datamenu-4",
        label: "Fiat Connect ////Me",
        url: "/connect-me",
        slug: "connect-me",
    },
    ...getProductDetails(productDetails),
];

export const dataMenuLabels: () => MenuLabels[] = () => [
    {
        numberId: 0,
        id: "datamenu-0",
        label: "Nova Fiat Toro",
        url: "/",
        slug: "",
    },
    {
        numberId: 1,
        id: "datamenu-1",
        label: "Design incomparável",
        url: "/design-incomparavel",
        slug: "design-incomparavel",
    },
    {
        numberId: 2,
        id: "datamenu-2",
        label: "Todas as versões",
        url: "/versoes",
        slug: "versoes",
    },
    {
        numberId: 3,
        id: "datamenu-3",
        label: "Fotos",
        url: "/fotos",
        slug: "fotos",
    },
    {
        numberId: 4,
        id: "datamenu-4",
        label: "Fiat Connect ////Me",
        url: "/connect-me",
        slug: "connect-me",
    },
    {
        numberId: 5,
        id: "tudo-sobre-toro",
        label: "Tudo sobre",
        url: "/tudo-sobre-toro",
        slug: "tudo-sobre-toro",
    },
    ...getProductDetails(productDetails),
];

export const dataMenuButtonsMobile: MenuCTAs[] = [
    {
        id: "datamenubutton-1",
        label: MONTE_TITLE,
        url: links.monte,
    },
    {
        id: "datamenubutton-2",
        label: COMPRE_TITLE,
        url: links.compre,
    },
    {
        id: "datamenubutton-3",
        label: FINANCIAMENTO_TITLE,
        url: links.financiamento,
    },
    {
        id: "datamenubutton-4",
        label: CONCESSIONARIAS_TITLE,
        url: links.concessionarias,
    },
    {
        id: "datamenubutton-5",
        label: OFERTAS,
        url: links.ofertas,
    },
];

export const dataMenuButtons: MenuCTAs[] = [
    {
        id: 'datamenubutton-1',
        label: MONTE_TITLE,
        url: links.monte,
        backgroundColor: '#FF1430',
        color: '#FFF',
    },
    {
        id: 'datamenubutton-2',
        label: COMPRE_TITLE,
        url: links.compre,
        backgroundColor: '#FF1430',
        color: '#FFF',
    },
];

export const versionCtaButton = [
    {
        id: 'ctaButton-0',
        label: TENHO_INTERESSE,
        url: links.compre,
        backgroundColor: '#FFFFFF',
        color: '#000000',
        excludeArrow: false,
    },
    {
        id: 'ctaButton-1',
        label: FICHA_TECNICA,
        url: files.accessoriesCatalogue,
        backgroundColor: '#363636',
        color: '#FFFFFF',
        download: true,
        excludeArrow: true,
        iconCode: '\f019'
    }
]
